import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
import { f } from "./utils-BzownMLO.js";
const LazyFormSelect = lazy(
  () => import("./form-select-Bpod9z74.js").then((module) => ({
    default: module.FormSelect
  }))
);
const LazySelect = lazy(
  () => import("./select-BFf2oqnw.js").then((n) => n.s).then((module) => ({
    default: module.Select
  }))
);
function FormSelect({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormSelect, { ...props });
}
const useFormSelectContent = () => {
  return useMemo(
    () => ({
      formSelect: {
        component: FormSelect
      }
    }),
    []
  );
};
export {
  LazyFormSelect as FormSelect,
  LazySelect as Select,
  f as findOptionByValue,
  useFormSelectContent
};
